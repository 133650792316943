<template>
   <div class="mt-16 mb-8">
      <div class="mx-auto animate__animated animate__fadeIn" :class="showOnboarding ? 'md:max-w-xl' : 'md:max-w-md' ">
         <div class="mx-5 md:mx-auto pt-6 pb-10 px-10 md:w-full bg-white rounded-md shadow-md">
            
            <h3 class="text-xl text-center font-semibold mt-2 text-indigo-900 mb-8 sm:mb-10" v-if="isTransactionActive == true">
               Login is required to continue
            </h3>
            <h3 class="text-3xl font-semibold mt-2 text-indigo-900 mb-8 sm:mb-10" v-else>
               <p class="text-gray-400 text-base mb-2" v-if="showOnboarding">Welcome {{ welcomeName | capitalize}}</p>
               <span v-if="!showOnboarding">Login</span><span class="text-2xl" v-else>Create your password...</span>
            </h3>
            <template v-if="!showOnboarding">
               <ValidationObserver v-slot="{ handleSubmit }">
                  <form class="flex flex-col justify-center mt-5" @submit.prevent="handleSubmit(login)">
                     <div class="flex flex-col space-y-4">
                        <ValidationProvider tag="div" name="Email" rules="required|email" v-slot="{ errors }">
                           <label for="email" class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Email</label>
                           <input type="email" v-model="form.email"
                              class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                              :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                              autocomplete="off" spellcheck="false"/>
                              <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider tag="div" name="Password" rules="required|min:8" v-slot="{ errors }">
                           <label for="password" class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Password</label>
                           <input type="password" v-model="form.password" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                              autocomplete="new-password" autocapitalize="off" spellcheck="false"/>
                           <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <div class="flex items-center justify-center pt-5">
                           <div class="flex items-center flex-shrink-0">
                              <input id="remember" type="checkbox" class="rounded mr-2" v-model="form.remember" />
                              <label
                                 for="remember"
                                 class="font-semibold text-center text-sm text-gray-500 flex-shrink-0 pt-1 text-sm text-heading cursor-pointer"
                                 >Remember me</label
                              >
                           </div>
                           <div class="flex ml-auto">
                              <router-link to="/auth/password/reset" class="font-semibold text-center text-sm text-gray-500 pt-1 text-end text-sm text-heading ps-3 underline hover:no-underline focus:no-underline focus:outline-none">
                                 Forgot password?
                              </router-link>
                           </div>
                        </div>
                        <div class="relative">
                           <button class="inline-flex items-center cursor-pointer transition 
                              ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md 
                              focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6 bg-indigo-900 
                              hover:bg-indigo-800 w-full mt-1.5" type="submit">
                              <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                 <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg> Login</button>
                        </div>
                     </div>
                  </form>
               </ValidationObserver>
               <div class="font-semibold text-center mt-5 mb-1 text-sm text-gray-500">
                  Don't have an Account?
                  <router-link to="/auth/sign-up" class="font-bold text-indigo-900">Register</router-link>
               </div>
            </template>
            <template v-else>
               <template v-if="loadingOnboarding">
                  <div class="col-span-2 h-96 mt-1/2 flex items-center justify-center">
                     <MazLoader />
                     <!-- During loading time we check if user is registered -->
                  </div>
               </template>
               <temaplate v-else>
                  <ValidationObserver v-slot="{ handleSubmit }">
                     <form class="flex flex-col justify-center mt-5" @submit.prevent="handleSubmit(completeOnboarding)">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                           <ValidationProvider tag="div" name="Email" class="col-span-2" rules="required|email" v-slot="{ errors }">
                              <label for="email" class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Email</label>
                              <input type="email" v-model="onBoardingData.email" readonly aria-readonly="true"
                                 class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                 autocomplete="off" spellcheck="false"/>
                                 <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <div class="col-span-2">
                              <h3 class="text-xl text-indigo-900 font-semibold border-b border-gray-100 py-3">Setup Password</h3>
                           </div>
                           <ValidationProvider tag="div" name="password" rules="required|min:8" vid="confirmation" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Password</label>
                              <input type="password" v-model="form.new_password" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 autocomplete="new-password" autocapitalize="off" spellcheck="false"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                              <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" name="confirm password" rules="required|confirmed:confirmation" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Confirm Password</label>
                              <input type="password" v-model="form.password_confirmation" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 autocomplete="new-password" autocapitalize="off" spellcheck="false"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                              <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <div class="relative col-span-2 mt-5">
                              <button class="inline-flex items-center cursor-pointer transition 
                                 ease-in-out duration-300 font-medium font-body text-center justify-center border-0 border-transparent rounded-md 
                                 focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6 bg-indigo-900 
                                 hover:bg-indigo-800 w-full mt-1.5" type="submit">
                                 <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                 </svg> Create account</button>
                           </div>
                        </div>
                     </form>
                  </ValidationObserver>
               </temaplate>
            </template>
         </div>
         <template v-if="isTransactionActive == true">
            <router-link to="/transaction/start" class="flex font-semibold justify-center text-indigo-900 text-base py-5 cursor-pointer hover:opacity-90 transition">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
               </svg>
               <div class="mx-3">Back to transaction</div>
            </router-link>
         </template>
      </div>
   </div>
</template>


<script>
export default {
   name: "Login",

   data() {
      return {
         form: {
            platform: "web"
         },
         isTransactionActive: false,
         isLoading: false,
         showOnboarding: false,
         onBoardingData: null,
         welcomeName: null,
         loadingOnboarding: false
      }
   },

   methods: {
      getTransactionData() {
         let getRole = window.localStorage.getItem('transaction-role');
         getRole = JSON.parse(getRole)
         console.log(getRole)
         if(getRole.role == 'seller') {
            this.form.email = getRole.formData.seller_email
         } else {
            this.form.email = getRole.formData.buyer_email
         }

         return getRole
      },

      async login() {
         this.isLoading = true
         try {
            let res = await this.$store.dispatch('auth/login', this.form)
            this.isLoading = false
            if(res.message === 'proceed onboarding') {
               this.loadingOnboarding = true
               this.getOnBoardingData()               
            } else {
               this.$toast.success(res.message)
               if( this.isTransactionActive == true) {
                  this.$router.push({ name: 'NewTransaction' })
               } else {
                  this.$router.push({ name: 'UserLoading'})
               }
            }
         } catch (err) {
            this.isLoading = false
            if(err.response && err.response.status == 400 || err.response.status == 422) {
					this.$toast.error(err.response.data.message)
				}
         }
      },

      async getOnBoardingData() {
         this.showOnboarding = true
         window.localStorage.setItem('isOnBoarding', true)

         try {
            let res = await this.$store.dispatch('auth/getOnboardingUserData')
            this.onBoardingData = res.data
            this.welcomeName = this.onBoardingData.account.first_name
            setTimeout(() => {
               this.loadingOnboarding = false
            }, 5000)
         } catch (error) {
            this.loadingOnboarding = true
         }
      },

      async completeOnboarding() {
         this.isLoading = true
         this.form.email = this.onBoardingData.email
         this.form.password = this.form.new_password
         
         try{
            let res = await this.$store.dispatch('auth/finishOnboarding', this.form)
            this.$toast.success(res.message)
            setTimeout(() => {
               if( this.isTransactionActive == true) {
                  this.$router.push({ name: 'NewTransaction' })
               } else {
                  this.$router.push({ name: 'UserLoading'})
               }
            }, 4000)
         } catch(error) {
            this.isLoading = false
            if(error.response && error.response.status == 400 || error.response.status == 422) {
					this.$toast.error(
						error.response.data.errors.email ?
						error.response.data.errors.email[0] :
						error.response.data.errors.phone ? 
						error.response.data.errors.phone[0] :
                  ''                 
					)
				}
         }
      }
   },

   mounted() {
      if(this.getTransactionData() != null) {
         this.isTransactionActive = true
      }

      let onboarding_stat = window.localStorage.getItem('isOnboarding')
      if( onboarding_stat == true ) {
         this.showOnboarding = true
      }
      
      if(this.$route.query.onboarding.length != 0) {
         this.showOnboarding = true
         this.loadingOnboarding = true
         this.form.email = this.$route.query.onboarding
         this.form.password = 'user1234'
         this.login()
         this.getOnBoardingData()
         
      }
   }
}
</script>